<template>
  <div style="height: 100%; min-height: 500px; position:relative;">

    <div id="overlay-message" v-if="showOverlayMessage">
      <div class="message-box overlay-box">
        <div class="message-box-content">
          <div class="message-box-container">
            <div class="message-box-message">
              <p>Aktiviere und erlaube den Zugriff auf dein GPS, um Kultur in deiner Nähe zu finden.</p>
            </div>

            <center><button class="btn mt20 btn-focus-design" @click="getUserLocation">Zugriff auf GPS erlauben</button></center>

          </div>
        </div>
      </div>
    </div> 

    <l-map ref="map" class="map" id="theMap" :zoom="karte.zoom" :center="karte.center" :options="karte.mapOptions">

      <l-tile-layer
      :name="karte.selectedTile.name"
      :url="karte.selectedTile.url"
      :attribution="karte.selectedTile.attribution"
      layer-type="base"
      />

      <!--Anzeige für einen Marker-->
      <l-marker v-if="userPosition!=null" :lat-lng="userPosition" :icon="userIcon"></l-marker>

      <v-marker-cluster ref="cluster" :options="karte.clusterOptions">
        <l-marker :id="'content-marker-'+content.id" :ref="'content-marker-'+content.id" v-for="content in contentsWithLocations" :key="content.id" :lat-lng="content.latLng" :icon="customIcon">
          <l-popup>
            <institution-marker :content="content"/>
          </l-popup>
        </l-marker>
      </v-marker-cluster>
    </l-map>
  </div>
</template>

<script>
  import 'leaflet/dist/leaflet.css';
  import { latLng, icon } from 'leaflet';
  import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
  import { showLoader, hideLoader } from '@/utils/helpers';
  import { search } from '@/api/content';
  import { getFieldValues } from '@/utils/helpers';

  import MarkerCluster from './markerClusterExtension';
  import InstitutionMarker from './MarkerInstitutionContent.vue';

  export default {
    name: 'SingleMapView',
    components: {
      LTileLayer,
      'v-marker-cluster': MarkerCluster,
      InstitutionMarker,
      LMarker,
      LPopup,
      LMap,
    },
    props: {
      height: {
        type: String,
        default: '700px',
      },
      addressObj: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        showOverlayMessage:true,
        userPosition: null,
        customIcon: icon({
          iconUrl: '/assets/icons/marker-normal.svg',
          shadowUrl: '',
          iconSize: [50, 50],/* size of the icon */
          iconAnchor: [25, 45], /* point of the icon which will correspond to marker's location */
          popupAnchor: [0, -41],
        }),
        userIcon: icon({
          iconUrl: '/assets/icons/marker-point.png',
          shadowUrl: '',
          iconSize: [40, 40],/* size of the icon */
          iconAnchor: [20, 20], /* point of the icon which will correspond to marker's location */
          popupAnchor: [0, -41],
        }),
        dataSet: {
          total: 0,
          data: null,
          meta:null,
          query: {
            page:1,
            limit: 8,
            keyword: '',
            onlyWithLocation: true,
            type: 'view',
            resourceType: 'view',
            selectedLatLng: {
              point: null,
              distance: 2000,
            },
            selectedAppId: this.$appId,
            selectedContentTypeId: this.$institutionId,
            sort: {
              prop: 'title',
              order: 'ascending'
            },
          },
        },
        karte: {
          modus: false,
          zoom: 15,
          center: latLng(54.3232927, 10.1227652),
          mapOptions: {
            zoomSnap: 0.5,
            zoomControl: true,
            touchZoom: true,
            dragging: true,
            doubleClickZoom: true,
            scrollWheelZoom: true,
            keyboard: true,
          },
          clusterOptions: {
            spiderfyOnMaxZoom: true,
            showCoverageOnHover: false,
          },
          selectedTile: {
           url:"https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
           attribution:"&copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors &copy; <a href=\"https://carto.com/attributions\">CARTO</a>"
         },
       },
     };
   },
   computed: {
    contentsWithLocations(){
      var locationContents=[];

      if(this.dataSet.data!=null){
        for(var i=0; i<this.dataSet.data.length; i++){
          this.getLatLongForContent(this.dataSet.data[i]);
          if(this.dataSet.data[i].latLng != null){
            locationContents.push(this.dataSet.data[i]);
          }
        }
      }
      return locationContents;
    },
    markerData(){
      if(this.addressObj!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'latitude') && this.addressObj.latitude!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'longitude') && this.addressObj.longitude!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'street') && this.addressObj.street!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'zipcode') && this.addressObj.zipcode!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'city') && this.addressObj.city!=null){

        var mapMarker = {lat:this.addressObj.latitude, long:this.addressObj.longitude, street:this.addressObj.street, zipcode:this.addressObj.zipcode, city:this.addressObj.city, latLng:latLng(this.addressObj.latitude,this.addressObj.longitude)}; 
        return mapMarker;
      }
      return null;
    },
    mapCenter(){
      if(this.markerData!=null){
        return this.markerData.latLng;
      }
      return latLng(54.3232927, 10.1227652);
    }
  },
  created() {

    navigator.permissions.query({name:'geolocation'}).then(result => {
      /*Will return ['granted', 'prompt', 'denied']*/
      console.log(result.state);
      if(result.state == "granted"){
        this.getUserLocation();
      }
    });

  },
  methods: {
    showLoader,
    hideLoader,
    getFieldValues,
    getUserLocation() {

      navigator.geolocation.getCurrentPosition( position => {   
       this.userPosition=latLng(position.coords.latitude, position.coords.longitude);
       this.karte.center=latLng(position.coords.latitude, position.coords.longitude);
       this.dataSet.query.selectedLatLng.point=latLng(position.coords.latitude, position.coords.longitude);
       this.showOverlayMessage = false;
       this.getContents();
     },     
     error =>
     {      

      this.$notify({
        type:'error',
        duration: 2500,
        title: 'Positionserkennung nicht möglich.',
        text: 'Ihre GPS-Position konnte nicht festgestellt werden.'
      });

      console.log(error);

    });   
    },
    getLatLongForContent(content){
      if(content == null){
        return;
      }

      var vals = null;
      vals = getFieldValues(content,'adresse');        

      /*check if there is a latitude and a longitude*/
      if(vals!=null && Object.prototype.hasOwnProperty.call(vals,'latitude') && Object.prototype.hasOwnProperty.call(vals,'latitude')){
        content.latLng=latLng(vals.latitude,vals.longitude);
        content.lat = vals.latitude;
        content.long = vals.longitude;
        return content.latLng;
      }
      content.lat = null;
      content.long = null;
      content.latLng= null;
      return null;
    },
    async getContents() {
      this.loader = this.showLoader(this.loader);
      const { limit, page } = this.dataSet.query;
      const { data, meta } = await search(this.dataSet.query);
      if(this.dataSet.data!=null){
        this.dataSet.data = this.dataSet.data.concat(data);
      }
      else{
        this.dataSet.data=data;            
      }
      data.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.dataSet.meta = meta;
      this.dataSet.total = meta.total;
      this.loader = this.hideLoader(this.loader);
    },
  },
};
</script>
<style lang="scss" scoped>

  .leaflet-container .leaflet-marker-pane img{
    -webkit-filter: drop-shadow( 3px 3px 4px rgba(0, 0, 0, .5));
    filter: drop-shadow( 3px 3px 4px rgba(0, 0, 0, .5));
  }

  #overlay-message {
  position: absolute; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 1002; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.overlay-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.message-box{
  min-width: 250px;
  background-color: #fff;
  border-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-left-radius: 30px;
  background: #fff;
  border: none;
  box-shadow: -5px 5px 15px 0px rgba(0, 24, 62, 0.2);
  padding: 25px;
  color: #000;
}

.btn{
  background-color: #E30059;
  color: #fff;
  border: 2px solid #E30059;
  outline: none;
  box-shadow: none;

  &:hover {
    background-color: #FFF;
    color: #E30059;
  }
}


</style>

