<template>
    <div>
        <div class="tour" :style="karteListeStyle">
            <Breadcrumb />

            <div class="left-sidebar hide-on-mobile">
                <div class="panel-header ml50">
                    <div class="page-title">
                        <h5>KulTouren</h5>
                        <p>Entdecke Kiels Stadtteile</p>
                    </div>

                    <button @click="showFilter = !showFilter" class="btn btn-fill icon-after float-right btn-focus-design" aria-label="Weitere Filter anzeigen">
                        Filter
                        <i class="material-icons" aria-hidden="true">{{ !showFilter ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</i>
                    </button>
                </div>

                <div class="filter-section mt20" v-show="showFilter">
                    <filter-box @filtersChanged="filtersChanged"/>
                </div>

                <div class="row card-sections">
                    <div class="col-12" v-for="(item, index) in kultourDataSet.data" :key="index">
                        <CardKultour v-if="kultourDataSet.total>=1" :collection="item" :id="'vertical-collection-'+kultourDataSet.data.id" :isSmall="false" :marginBottom="'15px'" :disableLink="true" @highlight="highlightThisContent"/>
                        </div>
                        <div class="no-kultour" v-if="kultourDataSet.data<1">
                            <div class="container-fluid blank">
                                <div class="blank-section">
                                    <img src="/assets/blank-image.png" alt="Blank">
                                    <h4 class="mt20">Keine KulTouren gefunden.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="map-section">
                    <the-map ref="theMap" v-if="active === SECTIONS.KARTE && kultourDataSet.data!=null" :collections="kultourDataSet.data" @highlight="highlightThisContent" ></the-map>
                    <div class="only-on-mobile mt30">
                        <div class="btn-custom btn-list-map-switch">
                            <button :class="['btn btn-custom__item', active === SECTIONS.KARTE ? 'active' : '']" @click="active = SECTIONS.KARTE">Karte</button>
                            <button :class="['btn btn-custom__item', active === SECTIONS.LISTE ? 'active' : '']" @click="active = SECTIONS.LISTE">Liste</button>
                        </div>
                        <div v-if="active === SECTIONS.KARTE" class="scroll-container horizontal-scroll">
                            <div v-for="(item, index) in kultourDataSet.data" :key="index" class="horizontal-scroll-card">
                                <CardKultour v-if="kultourDataSet.total>=1" :id="'horizontal-collection-'+item.id" :collection="item" :isSmall="true" :marginBottom="active === SECTIONS.KARTE ? '0' : '20px'" :marginRight="active === SECTIONS.LISTE ? '0' : '20px'" :disableLink="true" @highlight="highlightThisContent" class="map-card"/>
                                </div>
                            </div>

                            <div v-if="active === SECTIONS.LISTE" class="list-container">
                                <div v-for="(item, index) in kultourDataSet.data" :key="index" class="horizontal-scroll-card">
                                    <CardKultour v-if="kultourDataSet.total>=1" :id="'horizontal-collection-'+item.id" :collection="item" :isSmall="true" :marginBottom="active === SECTIONS.KARTE ? '0' : '20px'" :marginRight="active === SECTIONS.LISTE ? '0' : '20px'" @highlight="highlightThisContent" class="list-card"/>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>



                    <div class="footer-margin hide-on-mobile">
                    </div>
                </div>
            </template>

            <script>
                import { getAppCollections } from '@/api/collection';
                import { search } from '@/api/content';
                import { showLoader, hideLoader, isset } from '@/utils/helpers';
                import { isMobile } from 'mobile-device-detect';
                import VueScrollTo from 'vue-scrollto';

                const SECTIONS = Object.freeze({
                    KARTE: 'KARTE',
                    LISTE: 'LISTE'
                });

                export default {
                    name: 'KulTouren',
                    components: {
                        CardKultour: () =>import('@/components/cards/CardKultour.vue'),
                        FilterBox: () =>import('./components/filterBox.vue'),
                        Breadcrumb: () =>import('@/components/controls/Breadcrumb.vue'),
                        TheMap: () =>import('./components/tourenIndexMap.vue'),

                    },
                    data() {
                        return {
                            loader: null,
                            kultourDataSet: {
                              total: 0,
                              data: null,
                              meta:null,
                              query: {
                                page:1,
                                limit: 1000,
                                type: 'view',
                                resourceType: 'view',
                                selectedContentTypeId: this.$kultourId,
                                keyword: '',
                                sort: {
                                  prop: 'title',
                                  order: 'ascending'
                              },
                          },
                      },
                      isItMobile: isMobile ? true : false,
                      showFilter: false,
                      SECTIONS,
                      active: SECTIONS.KARTE,
                      scrollVerticalOptions: {
                        container: '.card-sections',
                        easing: 'ease-in',
                        lazy: false,
                        offset: -60,
                        force: true,
                        cancelable: true,
                        x: false,
                        y: true,
                    },
                    scrollHorizontalOptions: {
                        container: '.horizontal-scroll',
                        easing: 'ease-in',
                        lazy: false,
                        offset: -60,
                        force: true,
                        cancelable: true,
                        x: true,
                        y: false,
                    },
                }
            },
            computed:{
                karteListeStyle(){
                    if(this.active===SECTIONS.KARTE)
                        return "height: 100vh !important;"
                    else{
                        return "height: auto !important;margin-bottom:152px;"
                    }
                }
            },
            created(){
                this.getKultourenList();
            },
            methods: {
                showLoader,
                hideLoader,
                async getKultourenList() {
                    this.loader = this.showLoader(this.loader);
                    const { limit, page } = this.kultourDataSet.query;
                    const { data, meta } = await search(this.kultourDataSet.query);
                    this.kultourDataSet.data=data;
                    data.forEach((element, index) => {
                        element['index'] = (page - 1) * limit + index + 1;
                    });

                    this.kultourDataSet.meta = meta;
                    this.kultourDataSet.total = meta.total;
                    this.loader = this.hideLoader(this.loader);
                },
                filtersChanged(selectedFilters){
                    this.kultourDataSet.query.page = 1;
                    this.kultourDataSet.query.filters = JSON.stringify(Array.from(selectedFilters.entries()));
                    this.getKultourenList();
                },
                highlightThisContent(params){
                    Object.keys(this.kultourDataSet.data).forEach((k) => {
                     this.kultourDataSet.data[k].highlight = false;
                     if(this.kultourDataSet.data[k].id == params.collection.id){
                        this.kultourDataSet.data[k].highlight = true;

                        if(params.sender != "map"){
                        /*open the popup*/
                            this.$refs.theMap.openPopup(params.collection);
                        }
                        else{
                           if(this.isItMobile){
                            VueScrollTo.scrollTo(('#horizontal-collection-'+this.kultourDataSet.data[k].id), '2000',this.scrollHorizontalOptions);
                        }
                        else{
                            VueScrollTo.scrollTo(('#vertical-collection-'+this.kultourDataSet.data[k].id), '2000',this.scrollVerticalOptions);
                        }
                    }
                }
            });
                }
            }
        }
    </script>

    <style lang="scss" scoped>
        @import '@/scss/_variables.scss';

        .no-kultour{

            .container-fluid{
                max-width: 250px;
                margin: 0 auto;
                text-align: center;
            }

            .blank{
                display: flex;
                align-items: center;
                height: 80vh;
            }
        }

        .filter-section{
            margin-bottom: 30px;
        }

        .tour {
            position: relative;
            padding-top: 70px;
            display: flex;
            height: 100vh;
            overflow: hidden;
            z-index: 0;
            background: transparent;

            @media(max-width: 786px){
                height: 100vh;
            }

            @media (max-width: 350px) {
                padding-top: 60px;
            }

            .left-sidebar {
                flex: 0 450px;
                background: #fff;
                padding: 40px 20px 0 40px;

                @media (max-width: 991px){
                 flex: 0 350px;
             }

             .panel-header {
                display: flex;
                justify-content: space-between;

                .page-title {
                    h5 {
                        margin-bottom: 10px;
                    }
                }

                h5, p{
                    margin-bottom: 0;
                    line-height: 0.8em;
                }

                div {
                    flex: 1;
                }

                button {
                    flex: 0 10px;
                    max-height: 40px;
                }
            }

            .card-sections {
                margin-right: 0px;
                max-height: 100%;
                overflow: hidden;
                overflow-y: auto;
                margin-top: 20px;
                padding-bottom: 80px;

                &::-webkit-scrollbar-track {
                    padding: 2px;
                    margin-bottom: 20px;
                    background-color: rgba($color: #c4c4c4, $alpha: 0.4)
                }

                &::-webkit-scrollbar {
                    width: 10px;
                    padding: 2px;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
                box-shadow: inset 0 0 6px rgba($color: #a4a4a4, $alpha: 0.8);
                background-color: rgba($color: #c4c4c4, $alpha: 0.8);
                border: 2px solid rgba($color: #c4c4c4, $alpha: 0.4);
            }
        }
    }
    
    .map-section {
        flex: 1;
        background: #fff;

        img {
            position: absolute;
            height: 100%;
        }

        .scroll-container {
            position: absolute;
            top: 55vh;
            z-index: 1001;
            width: 100vw;

            @media (max-width: 786px){ top: auto; bottom: 10px; }

            .horizontal-scroll-card{
                margin-right: 15px;
            }

        }

        .list-container {
            position: relative;
            top: 50px;
            max-width: 500px;
            margin: 0 auto;
            margin-bottom: 200px;
            padding: 0 15px;

            .list-card{
                @media(max-width:992px){
                    min-height: 175px;
                }
            }
        }

        .btn-custom {
            position: relative;
            display: block;
            width: 200px;
            margin: 0 auto;
            top: 22px;
            border-radius: 30px;
            box-shadow: -5px 5px 15px 0px rgba($color: $primary, $alpha: 0.2);


            &.btn-list-map-switch {
                z-index: 1001;
                position: absolute;
                top: 110px;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &__item {
                padding: 10px 25px;
                border: 0;
                width: 100px;
                background: #fff;
                font-size: 16px;
                font-family: 'elliot-regular';
                font-weight: 400;
                text-transform: capitalize;
                color: $darkGray;
                border-radius: 0;

                &:first-child {
                    border-top-left-radius: 30px;
                    border-bottom-left-radius: 30px;
                }
                &:last-child {
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;
                }

                &.active {
                    background: $infoColor;
                    font-weight: 700;
                    color: #fff;
                }
            }
        }
    }
}

.map-card {
    @media(max-width:991px){
        height:100%
    }
}
</style>
