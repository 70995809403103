<template>
    <div>
        <div class="suche">
            <Breadcrumb />
            <Search :title="'Suche'" :keyword="dataSet.query.keyword" @reload="searchParamsHaveChanged"/>

            <div class="container-fluid blank" v-if="dataSet.total==0">
                <div class="blank-section">
                    <img src="/assets/blank-image.png" class="search-img" alt="Blank">
                    <p>Bitte gib einen Suchbegriff ein oder wähle einen Filter aus.</p>
                </div>
            </div>

            <div class="container-fluid footer-margin" style="background-color: #fff;">
                <div class="container mt40 mb30" v-if="dataSet.total>0">
                    <div v-if="institutions!=null && institutions.length>0">
                        <div class="row">
                           <div class="col-12 mb30">
                            <h3 class="title">Anbieter</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 directory--element__single" v-for="institution in institutions" :key="institution.id">
                            <CardInstitution :content="institution" :style="'15px'"/>
                        </div>
                    </div>
                </div>
                <div v-if="angebote!=null && angebote.length>0">
                    <div class="row">
                       <div class="col-12 mb30 mt30">
                        <h3 class="title">Veranstaltungen</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6 directory--element__single" v-for="angebot in angebote" :key="angebot.id">
                        <CardEvent :content="angebot" :style="'15px'"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="footer-margin hide-on-mobile">
</div>

</div>
</template>

<script>
    import Breadcrumb from '@/components/controls/Breadcrumb.vue';
    import CardEvent from '@/components/cards/CardEvent.vue';
    import CardInstitution from '@/components/cards/CardInstitution.vue';
    import Search from '@/components/Search.vue';
    import { showLoader, hideLoader, isset } from '@/utils/helpers';
    import { search } from '@/api/content';

    export default {
        name: 'Suche',
        components: {
            CardEvent,
            CardInstitution,
            Breadcrumb,
            Search
        },
        data() {
            return {
                loader: null,
                dataSet: {
                  total: 0,
                  data: null,
                  meta:null,
                  query: {
                    page:1,
                    limit: 18,
                    keyword: '',
                    onlyWithLocation: true,
                    type: 'view',
                    resourceType: 'view',
                    selectedAppId: this.$appId,
                    selectedContentTypeId: [this.$institutionId,this.$offerId],
                    sort: {
                      prop: 'title',
                      order: 'ascending'
                  },
              },
          },
      }
  },
  watch: {
    '$route.query.keyword': {
      handler: function(search) {
        this.dataSet.query.keyword = search;
        this.getContents();
    },
    deep: true,
    immediate: true
},
},
computed:{
    uriKeyword(){
        return this.$route.query.keyword;
    },
    sortedContents(){
        var offers = [];
        var institutions = [];
        if(this.dataSet.data!=null){
            for(var i = 0; i < this.dataSet.data.length; i++){
                var data = this.dataSet.data[i];
                if(data.content_type_name == 'angebot'){
                    offers.push(data);
                }
                else if(data.content_type_name == 'institution'){
                    institutions.push(data);
                }
            }
        }
        return {'offers':offers,'institutions':institutions};
    },
    angebote(){
        return this.sortedContents.offers;
    },
    institutions(){
        return this.sortedContents.institutions;
    }
},
methods: {
    showLoader,
    hideLoader,
    searchParamsHaveChanged(query){
        this.dataSet.query.keyword = [];
        Object.keys(query).forEach((k) => {
            this.dataSet.query.keyword.push(query[k]);
        });

        if(!this.isQueryEmpty(this.dataSet.query.keyword)){
            this.getContents();
        }
        else{
            this.dataSet.total=0;
            this.dataSet.data=null;
        }
    },
    isQueryEmpty(keyword){
        var isEmpty = true;
        if(Array.isArray(keyword)){
            for(var i = 0; i < keyword.length; i++){
                if(keyword[i]!=null && keyword[i]!=''){
                    //console.log('Multi Keyword: '+keyword);
                    isEmpty = false;
                }
            }
        }
        else if(keyword!=null && keyword!=''){
            //console.log('Single Keyword: '+keyword);
            isEmpty=false;
        }
        return isEmpty;
    },
    async getContents() {
        this.loader = this.showLoader(this.loader);
        const { limit, page } = this.dataSet.query;
        const { data, meta } = await search(this.dataSet.query);
        this.dataSet.data=data;            
        data.forEach((element, index) => {
            element['index'] = (page - 1) * limit + index + 1;
        });
        this.dataSet.meta = meta;
        this.dataSet.total = meta.total;
        this.loader = this.hideLoader(this.loader);
    },
}
}
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .suche {

        height: 100vh;

        .blank {
            display: flex;
            align-items: center;
            height: 58vh;

            @media(max-width: 560px){
                height:20vh;
            }

            &-section {
                max-width: 250px;
                margin: 0 auto;
                text-align: center;

                p {
                    font-size: 16px;
                    line-height: 24px;
                    margin-top: 20px;
                }
            }
        }
    }

    .search-img {
        @media(max-width: 560px){
           display: none;
       }
   }
</style>
